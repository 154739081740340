import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PiNavigationArrowFill } from "react-icons/pi";


const StorageListing = () => {
  return (
    <Container className="py-5 d-flex flex-column gap-3">
      <Row className="justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 sj-heading">
          <p className="blueTextColr">
            Your <span style={{ color: "#FFCC00" }}>Storage-Listing</span> Journey, Simplified.
          </p>
        </div>
      </Row>
      <Row className="text-center mt-5">
          <Col md={4}>
            <Card className="border-0" style={{ boxShadow: "none", height: "500px" }}>
              <div
                style={{
                    paddingTop: '54px',
                    paddingRight: '54px',
                  backgroundColor: "#F5F6FA",
                  border: "none",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70%",
                  overflow: "hidden",
                }}
              >
                <img
                  src="/images/slisting1.png"
                  alt="Sign Up & Start"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", 
                    borderTopRightRadius:'25px',borderBottomLeftRadius:'25px',
                  }}
                />
              </div>
              <div className="mt-3 text-start">
              <p className="unit-headings">Sign Up & Start</p>
              <p className="text-justify" style={{color:'#343434'}}>
                Join our community of trusted storage providers. Create your account
                quickly and unlock access to tools that help you succeed.
              </p>
              </div>
            </Card>
          </Col>
          <Col md={4}>
          <Card className="border-0" style={{ boxShadow: "none", height: "500px" }}>
                <div
                    style={{
                    position: "absolute",
                    top: "50px",
                    right: "5px", 
                    display: "flex",
                    gap: "10px",
                    zIndex:30,
                    }}
                >
                    <PiNavigationArrowFill
                        style={{
                            color: "#000034", 
                            width: "20px", 
                            height: "20px",
                        }}
                        />

                    <span
                    style={{
                        marginTop:'18px',
                        backgroundColor: "#FFCC00",
                        color: "white",
                        borderTopLeftRadius:'2px',
                        borderTopRightRadius:'24px',
                        borderBottomLeftRadius:'24px',
                        borderBottomRightRadius:'24px',
                        padding: "8px 16px",
                        fontSize: "16px",
                        fontWeight: "500",
                        border: "2px solid #DBB310"
                    }}
                    >
                    Upload
                    </span>

                </div>
                <div
                    style={{
                    position: "absolute",
                    top: "261px",
                    left:"106px",
                    display: "flex",
                    gap: "10px",
                    zIndex:30,
                    }}
                >
                    <PiNavigationArrowFill
                        style={{
                            color: "#000034", 
                            width: "20px", 
                            height: "20px",
                        }}
                        />

                    <span
                    style={{
                        marginTop:'18px',
                        backgroundColor: "#B6B4FF",
                        color: "white",
                        borderTopLeftRadius:'2px',
                        borderTopRightRadius:'24px',
                        borderBottomLeftRadius:'24px',
                        borderBottomRightRadius:'24px',
                        padding: "8px 16px",
                        fontSize: "16px",
                        fontWeight: "500",
                        border: "2px solid #8280FF"
                    }}
                    >
                    Add Details
                    </span>

                </div>

            <div
                style={{
                paddingBottom: '54px',
                paddingRight: '54px',
                backgroundColor: "#F5F6FA",
                border: "none",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70%",
                overflow: "hidden",
                position: "relative",
                }}
            >
                <img
                src="/images/slisting2.png"
                alt="Showcase Your Space"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderTopLeftRadius: '25px',
                    borderBottomRightRadius: '25px',
                }}
                />
            </div>

            <div className="mt-3 text-start">
                <p className="unit-headings">Showcase Your Space</p>
                <p className="text-justify" style={{ color: '#343434' }}>
                Add your unit details, upload eye-catching photos, set your price, and let renters know what makes your space unique.
                </p>
            </div>
            </Card>

          </Col>
          <Col md={4}>
            <Card className="border-0" style={{ boxShadow: "none", height: "500px" }}>
              <div
                style={{
                    paddingTop: '54px',
                    paddingLeft: '30px', 
                  backgroundColor: "#F5F6FA",
                  border: "none",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70%", 
                  overflow: "hidden",
                }}
              >
                <img
                  src="/images/slisting3.png"
                  alt="Sign Up & Start"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", 
                    //cover
                    //contain
                    //fill
                  }}
                />
              </div>
              <div className="mt-3 text-start">
              <p className="unit-headings">Manage with Ease</p>
              <p className="text-justify" style={{color:'#343434'}}>
              Streamline everything with our all-in-one dashboard. Track bookings, answer inquiries, and oversee payments—all in one convenient place.
              </p>
              </div>
            </Card>
          </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          width: "fit-content",
          margin: "0 auto",
        }}
      >
        <Link
          to={`http://blog.findxstorage.com/`}
          style={{
            textDecoration: "none",
          }}
        >
          <button
            style={{
              fontWeight: "700",
              fontSize: "18px",
              backgroundColor: "#ffcc00",
              border: "none",
              borderRadius: "15px",
              color: "#000034",
              padding: "8px 30px",
            }}
          >
            Start Listing
          </button>
        </Link>
      </Row>
    </Container>
  );
};

export default StorageListing;
