import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import dummyClients from "../../../../dummyClients";

const HomeClientsTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(100);
  const [slideWidth, setSlideWidth] = useState("100%");
  
  useEffect(() => {
    const getTranslateValue = () => {
      if (window.innerWidth >= 1300) return 72;
      if (window.innerWidth >= 1200) return 76;
      if (window.innerWidth >= 1024) return 82;
      if (window.innerWidth >= 992) return 82;
      if (window.innerWidth >= 768) return 100;
      if (window.innerWidth < 768) return 110;
      return 83;
    };

    setTranslateValue(getTranslateValue());
    // console.log("value is " , getTranslateValue());
    
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setSlideWidth("70%");
      } else if (window.innerWidth >= 992) {
        setSlideWidth("80%"); 
      } else if (window.innerWidth >= 768) {
        setSlideWidth("100%"); 
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check for window size
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + dummyClients.length) % dummyClients.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % dummyClients.length);
  };

  return (
    <Container className="px-4 my-5">
      <Row className="mt-4">
        <div
          className="d-flex flex-row"
          style={{
            transform: `translateX(-${currentIndex * translateValue}%)`,
            transition: "transform 0.5s ease",
          }}
        >
          {dummyClients.map((slide, index) => (
           <div
           key={index}
           className="rounded-4 d-flex flex-md-row mx-3"
           style={{
             padding: '5px',
             backgroundColor: '#ffcc00',
             width: slideWidth,
             flex: '0 0 auto', 
             height: 'auto',
           }}>
              <div style={{ flex: '0 0 auto', width: '150px' }} className="d-none d-sm-block">
                <Image
                  src={slide.profilePic}
                  alt="Client testimonial"
                  className="rounded-4"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>

              <div
                className=""
                style={{
                  flex: '1',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
              >
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <span className="text-white fs-4">★★★★★</span>
                  <div
                    className="ms-2 bg-white text-dark px-2 py-1 rounded-1 d-inline-flex align-items-center"
                    style={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                  >
                    {slide.rating}
                  </div>
                </div>
                <p className="mt-3" style={{ textAlign: 'justify' }}>
                  {slide.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Row>

      <Row className="mt-4 justify-content-center flex-nowrap">
        <Col xs="auto" className="d-flex justify-content-center align-items-center">
          <button
            onClick={handlePrevClick}
            className="m-2 d-flex align-items-center justify-content-center"
            style={{
              fontWeight: '700',
              fontSize: '16px',
              backgroundColor: currentIndex === 0 ? '#FFF2C0' : '#FFCC00',
              border: 'none',
              borderRadius: '10px',
              color: 'white',
              width: '44px',
              height: '44px',
            }}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft size={18} />
          </button>
        </Col>

        <Col md={dummyClients.length} xs={8} className="d-flex justify-content-center align-items-center">
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '3px',
              borderRadius: '3px',
              position: 'relative',
              gap: '10px',
            }}
          >
            {dummyClients.map((_, index) => (
              <div
                key={index}
                style={{
                  width: '90px', 
                  backgroundColor: currentIndex === index ? '#FFCC00' : '#FFF2C0',
                  transition: 'background-color 0.3s ease',
                }}
              />
            ))}
          </div>
        </Col>

        <Col xs="auto" className="d-flex justify-content-center align-items-center">
          <button
            onClick={handleNextClick}
            className="m-2 d-flex align-items-center justify-content-center"
            style={{
              fontWeight: '700',
              fontSize: '16px',
              backgroundColor: currentIndex === dummyClients.length - 1 ? '#FFF2C0' : '#FFCC00',
              border: 'none',
              borderRadius: '10px',
              color: 'white',
              width: '44px',
              height: '44px',
            }}
            disabled={currentIndex === dummyClients.length - 1}
          >
            <FaChevronRight size={18} />
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeClientsTestimonials;
