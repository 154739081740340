import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaPlay, FaPause } from "react-icons/fa";

const Tutorials = () => {
  const [playing, setPlaying] = useState(null);
  const [visibleRows, setVisibleRows] = useState(1);  // Show 1 row initially
  const [isExpanded, setIsExpanded] = useState(false);  // Track if "Show More" is clicked
  const videoRefs = useRef({});

  const handlePlayPause = (videoId) => {
    Object.keys(videoRefs.current).forEach((id) => {
      if (id !== videoId && videoRefs.current[id]) {
        videoRefs.current[id].pause();
      }
    });
  
    const video = videoRefs.current[videoId];
    if (video.paused) {
      video.play();
      setPlaying(videoId);
    } else {
      video.pause();
      setPlaying(null);
    }
  };

  const videos = [
    { id: "tutorial1", title: "Organize Storage Unit like a Pro" },
    { id: "tutorial2", title: "Short of Space in Apartment?" },
    { id: "tutorial3", title: "Struggling with Storage?" },
    { id: "tutorial4", title: "Organizing Made Simple" },
    { id: "tutorial5", title: "Maximize Your Space" },
    { id: "tutorial6", title: "Packing for Storage" },
  ];

  // Adjust visible videos based on visibleRows
  const visibleVideos = videos.slice(0, visibleRows * 4); // Show visibleRows * 4 videos

  // Toggle between showing more or less
  const handleToggleVisibility = () => {
    if (isExpanded) {
      setVisibleRows(1);  // Show 1 row when collapsed
    } else {
      setVisibleRows(Math.ceil(videos.length / 4));  // Show all rows when expanded
    }
    setIsExpanded((prev) => !prev);  // Toggle the expanded state
  };

  return (
    <Container className="my-5">
      <Row className="mb-4">
        {visibleVideos.map((video, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "20px",
              }}
            >
              <video
                ref={(el) => (videoRefs.current[video.id] = el)}
                id={video.id}
                src={`/videos/${video.id}.mp4`}
                alt={`Video Thumbnail ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: '20px',
                }}
              />
              {playing !== video.id && (
                <div
                  onClick={() => handlePlayPause(video.id)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#0000349E",
                    width: "70px", 
                    height: "70px", 
                    borderRadius: "50%", 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <FaPlay size={23} color="white" />
                </div>
              )}
              {playing === video.id && (
                <div
                  onClick={() => handlePlayPause(video.id)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#0000349E",
                    width: "70px", 
                    height: "70px", 
                    borderRadius: "50%", 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <FaPause size={23} color="white" />
                </div>
              )}
            </div>
          </Col>
        ))}
      </Row>
        <div className="text-center mt-4">
          <button
            onClick={handleToggleVisibility}
            style={{
              fontWeight: '700',
              fontSize: '20px',
              backgroundColor: '#ffcc00',
              border: 'none',
              borderRadius: '10px',
              color: '#000034',
              paddingTop: '6px',   
              paddingBottom: '6px',
              paddingLeft: '28px', 
              paddingRight: '28px', 
            }}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
    </Container>
  );
};

export default Tutorials;
