import React ,{useState} from "react";
import { Row, Col, Button, Image } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { GiCheckMark } from "react-icons/gi";
import GeoApiAuto from "../../GeoApiAuto/GeoApiAuto";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import { LuLoader } from "react-icons/lu";

const WhyOrange = () => {
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [formData, setFormData] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const getLocationData = (data, id) => {
    console.log(" data is " , data);
    if (!data) {
      setIsLocationSet(false);
      return setFormData((prev) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }

    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };


    setFormData((prev) => {
    const updatedFormData = { ...prev, [id]: locationData.address };
    if (id === "location") setIsLocationSet(true); // Mark when the location is ready
    return updatedFormData;
  });
    setIsLocationSet(true);
  };

  const navigate = useNavigate();

  const submitFormHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    console.log("submit form data " , formData);
    await waitUntilLocationIsSet();
    console.log("submitinggggggg " , formData);


    if (!formData.location) {
      return toast.error("Location is Required.");
    }

    console.log("out ");
    setIsLoading(false);
    localStorage.setItem("us_Storage_facility", JSON.stringify(formData));

    navigate({
      pathname: "/storages/results",
      search: `?${createSearchParams(formData)}`,
    });
  };
  const waitUntilLocationIsSet = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        console.log(isLocationSet);
        if (isLocationSet) {
          clearInterval(interval); // Stop polling
          resolve();
        }
      }, 100); // Check every 100ms
    });
  };
  return (
    <div className="py-0 py-sm-5">
    <Row className="justify-content-center align-items-center flex-column flex-lg-row gap-4">
      {/* First Column */}
      <Col
        lg={6}
        className="position-relative d-none d-sm-block text-start mb-4 mb-md-0 align-items-center align-items-lg-start"
      >
        <div className="w-100">
          <div className="">
            <div
              className="d-flex gap-5"
            >
              <Image
                src="/images/whystorage1.jpg"
                alt="Car Storage"
                width={318}
                height={212}
                style={{
                  borderTopLeftRadius: '60px',
                  borderBottomRightRadius: '60px',
                }}
              />
              <div className="mx-5 text-white d-flex align-items-center justify-content-center">
              <svg width="91" height="163" viewBox="0 0 91 163" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="4.28433" cy="3.80609" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="4.28433" cy="34.8437" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="4.28433" cy="65.8813" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="4.28433" cy="96.9189" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="4.28433" cy="127.957" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="4.28433" cy="158.994" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="3.80609" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="34.8437" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="65.8813" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="96.9189" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="127.957" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="45.2355" cy="158.994" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="3.80609" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="34.8437" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="65.8813" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="96.9189" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="127.957" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              <ellipse cx="86.1877" cy="158.994" rx="3.89859" ry="3.28302" fill="#000034" fill-opacity="0.2"/>
              </svg>
              </div>
            </div>
  
            <div
              className="d-flex align-items-center position-absolute"
              style={{ right: '5rem', top: '2rem'}}
            >
              <div
                style={{
                  // style={{ width: '290px' , height:'58px',justifyContent:"center",alignItems:"center" }}
                  width:'290px',
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  paddingTop:'10px',
                  paddingBottom:'10px',
                  borderRadius: '10px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <GeoApiAuto id="location" getLocationData={getLocationData} />
                </div>
              </div>
              <div style={{marginLeft:'3px'}}>
                <Link to="/storages/results">
                  <button
                    type="button"
                    onClick={submitFormHandler}
                    disabled={!isLocationSet}
                    className="px-2 py-2"
                    style={{
                      fontWeight: '300',
                      fontSize: '18px',
                      backgroundColor: '#ffcc00',
                      border: 'none',
                      borderRadius: '5px',
                      color: '#000034',
                      // padding:'4px',
                      zIndex: 70,
                    }}
                  >
                      {isLoading ? <><LuLoader /></>: <CiSearch size={30} />}
                  </button>
                </Link>
              </div>
            </div>
          </div>
  
          <div className="d-flex gap-4 mt-4">
              <div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: '#000034',
                  width: '195px',
                  height: '184px',
                  borderTopRightRadius: '60px',
                  borderBottomLeftRadius: '60px',
                }}
              >
                <Image
                  src="/images/FindxStoragejustlogo.png"
                  alt="findxlogo"
                  width={100}
                  height={60}
                />
              </div>
              <div
                className="bg-white"
                style={{
                  // width: '250px',
                  // height: '100px',
                  borderRadius: '20px',
                  position:'relative',
                  left:'30%',
                  top:'10%',
                  zIndex:30
                }}
              >
                <p style={{
                  fontSize: '14px',
                  color:'#000034',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
                > 18k+ users all over USA</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className="rounded-circle overflow-hidden"
                    style={{
                      width: '50px',
                      height: '50px',
                      position: 'relative',
                      zIndex: 4,
                      border: '2px solid white',
                    }}
                  >
                    <img
                      src="/images/user1.png"
                      alt="Circle 1"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div
                    className="rounded-circle border border-white mx-n2 overflow-hidden"
                    style={{
                      width: '50px',
                      height: '50px',
                      position: 'relative',
                      zIndex: 5,
                      marginLeft:'-5%',
                      border: '2px solid white',
                    }}
                  >
                    <img
                      src="/images/user2.png"
                      alt="Circle 2"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div
                    className="rounded-circle overflow-hidden"
                    style={{
                      width: '50px',
                      height: '50px',
                      position: 'relative',
                      zIndex: 6,
                      marginLeft:'-5%',
                      border: '2px solid white',
                    }}
                  >
                    <img
                      src="/images/user3.png"
                      alt="Circle 3"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div
                    className="rounded-circle mx-n2 overflow-hidden"
                    style={{
                      width: '50px',
                      height: '50px',
                      position: 'relative',
                      zIndex: 7,
                      marginLeft:'-5%',
                      border: '2px solid white',
                    }}
                  >
                    <img
                      src="/images/user4.png"
                      alt="Circle 4"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div
                    className="rounded-circle border border-white overflow-hidden"
                    style={{
                      width: '50px',
                      height: '50px',
                      position: 'relative',
                      zIndex: 8,
                      marginLeft:'-5%',
                      border: '2px solid white',
                    }}
                  >
                    <img
                      src="/images/user1.png"
                      alt="Circle 5"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' , }}
                    />
                  </div>
                </div>


              </div>
              </div>
              <div
                className=""
                style={{
                  width: '270px',
                  height: '210px',
                  borderTopLeftRadius: '60px',
                  borderBottomRightRadius: '60px',
                }}
              >
                <Image
                  src="/images/whystorage3.jpg"
                  alt="Car Storage"
                  width={310}
                  height={270}
                  style={{
                    borderTopLeftRadius: '60px',
                    borderBottomRightRadius: '60px',
                  }}
                />
              </div>

          
          </div>
        </div>
      </Col>
  
      {/* Second Column */}
      <Col
        md={10}
        lg={5}
        className="text-start d-flex flex-column gap-3 align-items-center align-items-md-start "
      >
        <p className="sj-heading blueTextColr text-start">
          Why Find<span style={{ color: '#ffcc00' }}>X</span>Storage?
        </p>
        <p
          style={{
            fontSize: '22px',
            lineHeight: 1.4,
            fontWeight: 300,
            color: '#000034',
            textAlign: 'justify',
            letterSpacing:-0.4,
          }}
        >
          FindXStorage makes it easy for both storage unit operators to easily
          list available storage units and prospective renters to find available
          storage units. FindXStorage works with storage unit operators around
          the world so finding a storage unit is easy and convenient.
        </p>
        <ul className="list-unstyled d-flex flex-column gap-3">
          <li
            style={{
              fontSize: '22px',
              fontWeight: 600,
              color: '#000034',
            }}
          >
            <GiCheckMark color="#404040" size={18} /> 
            Largest Marketplace for Storage Space
          </li>
  
          <li
            style={{
              fontSize: '22px',
              fontWeight: 600,
              color: '#000034',
            }}
          >
            <GiCheckMark color="#404040" size={18} /> Find The Best Price and
            Features
          </li>
  
          <li
            style={{
              fontSize: '22px',
              fontWeight: 600,
              color: '#000034',
            }}
          >
            <GiCheckMark color="#404040" size={18} /> Convenient Online Move-ins
          </li>
        </ul>
        <Link to={`http://blog.findxstorage.com/`}
        style={{
          textDecoration: 'none',
        }}
        >
        <button
          style={{
            fontWeight: '700',
            fontSize: '18px',
            backgroundColor: '#ffcc00',
            border: 'none',
            borderRadius: '15px',
            color: '#000034',
            padding: '8px 30px',
          }}
        >
          Let's Start
        </button>
        </Link>
      </Col>
    </Row>
  </div>
  
  );
};

export default WhyOrange;
