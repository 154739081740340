import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const Learning = () => {
  const cardsData = [
    {
      image: "/images/learning1.png", 
      title: "The Ultimate Guide to Finding Storage Made Simple",
      author: "FindxStorage",
      date: "22 November 2024",
    },
    {
      image: "/images/learning2.png",
      title: "Compare, Choose, and Save: Your One-Stop Storage Solution",
      author: "FindxStorage",
      date: "22 November 2024",
    },
    {
      image: "/images/learning3.png",
      title: "Top Benefits of a 100% Digital Storage Experience",
      author: "FindxStorage",
      date: "22 November 2024",
    },
  ];

  return (
    <div className="container" style={{ marginTop: '120px' }}>
      <Row className="g-4"> {/* Add g-4 for consistent spacing between rows and columns */}
        {cardsData.map((card, index) => (
          <Col xs={12} sm={6} md={4} key={index} className="d-flex justify-content-center">
            {/* Set xs=12 for full width on extra small, sm=6 for two columns, and md=4 for three columns */}
            <Card style={{ border: 'none', boxShadow: 'none', width: '100%', maxWidth: '351px' }}>
              <Card.Img
                style={{ width: '100%', height: '290px', borderRadius: '15px' }}
                variant="top"
                src={card.image}
                alt={card.title}
              />
              <Card.Body>
                <Card.Title
                  style={{
                    fontSize: '22px',
                    fontWeight: '500',
                    color: '#000034',
                  }}
                >
                  {card.title}
                </Card.Title>
                <Card.Text className="d-flex justify-content-between align-items-center text-muted">
                  <span>
                    By <strong>{card.author}</strong>
                  </span>
                  <span>{card.date}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Learning;
