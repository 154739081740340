import React, { useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

const unitsData = [
  {
    id: 'small',
    label: 'Small Unit',
    sizeRange: '25 - 75 sq ft.',
    image: '/images/storageunitsmall.png',
    commonSizes: "5' x 5', 5' x 10', 5' x 15'",
    description:
      'Anywhere from a hall closet or half bathroom to a small bedroom depending on the storage unit size.',
    fits: 'Small items, like bags, suitcases and boxes to the contents of a small one-bedroom apartment.',
  },
  {
    id: 'medium',
    label: 'Medium Unit',
    sizeRange: '75 - 150 sq ft.',
    image: '/images/storageunitsmall.png',
    commonSizes: "10' x 10', 10' x 15', 10' x 20'",
    description:
      'Comparable to a small living room or a large bedroom, perfect for storage needs beyond small items.',
    fits: 'Contents of a one to two-bedroom apartment, including furniture and boxes.',
  },
  {
    id: 'large',
    label: 'Large Unit',
    sizeRange: '150 - 300 sq ft.',
    image: '/images/storageunitsmall.png',
    commonSizes: "10' x 20', 10' x 30'",
    description:
      'Big enough to fit the contents of a small house, including large furniture and appliances.',
    fits: 'Items from a two to three-bedroom home, such as large furniture, appliances, and more.',
  },
];

const HomeHotOffers = () => {
  const [activeUnit, setActiveUnit] = useState('small');
  const selectedUnit = unitsData.find((unit) => unit.id === activeUnit);

  return (
    <div className="container justify-content-center mt-5 px-5">

  <div className="d-flex flex-wrap justify-content-around px-0 px-md-2 px-lg-5 align-items-center my-5 gap-3 gap-lg-7 gap-md-5 overflow-hidden">
    {unitsData.map((unit, index) => (
      <React.Fragment key={unit.id}>
        <button
          className={`d-flex align-items-center ${
            activeUnit === unit.id ? 'activebtn text-white' : 'not-activebtn'
          }`}
          onClick={() => setActiveUnit(unit.id)}
          style={{}}
        >
          <span 
          className={`${
            activeUnit === unit.id ? 'bg-white yellowtext innerdivlabel' : 'innerdivlabel-not'
          }`}
          >{unit.label[0]}</span>
          <span className="ms-2 inner-span-label">{unit.label}</span>
        </button>
      </React.Fragment>
    ))}
  </div>

  {selectedUnit && (
     <Container 
     className="my-5" 
     style={{
       borderRadius: '25px',
       overflow: 'hidden',
       backgroundColor: '#F5F6FA',
       padding: '20px',
     }}
   >
     <Row className="g-0">
       <Col md={6}>
         <img
           src={selectedUnit.image}
           alt={selectedUnit.label}
           className="img-fluid"
           style={{
             height: '100%',
             objectFit: 'cover',
             borderRadius:'25px',
           }}
         />
       </Col>
       <Col md={6} className="p-2 p-sm-3 p-md-4 p-lg-5">
           <div className="container d-flex flex-column gap-5">
            <div>
            <span className="range-btn">{selectedUnit.sizeRange}</span>
            </div>
            <div className="d-flex flex-column gap-2">
                  <div className="lh-1">
                  <p className="unit-headings">Common unit sizes:</p>
                  <p className="unit-p">{selectedUnit.commonSizes}</p>
                </div>
                <div className="lh-1">
                  <p className="unit-headings">Looks like:</p>
                  <p className="unit-p">{selectedUnit.description}</p>
                </div>
                <div className="lh-1">
                  <p className="unit-headings">What will fit:</p>
                  <p className="unit-p">{selectedUnit.fits}</p>
                </div>
            </div>
            <div>
                <Link to={`/SizingGuidPage`}
                className="text-decoration-underline text-lg-start"
                style={{
                  fontSize: '24px',
                  fontWeight: 600,
                  textAlign: 'left',
                  color: '#ffcc00',
                  textUnderlineOffset: '3px',
                  cursor:'pointer',
                }}
                  >
                    Learn More
                    <MdNavigateNext />
                </Link>
            </div>
           </div>
           
       </Col>
     </Row>
   </Container>
     


    // <div
    //   className="card mx-auto"
    //   style={{
    //     width: '90%', 
    //     maxWidth: '1200px',
    //     position: 'relative',
    //     overflow: 'hidden',
    //     borderRadius: '20px',
    //   }}
    // >
    //   <img
    //     src={selectedUnit.image}
    //     className="card-img-top img-fluid"
    //     alt={selectedUnit.label}
    //     style={{ objectFit: 'cover' }}
    //   />

    //   <div
    //     className="overlay-content text-white p-4 d-flex flex-column"
    //     style={{
    //       position: 'absolute',
    //       bottom: '0',
    //       left: '0',
    //       height: '100%',
    //       width: '100%',
    //       background: `
    //         linear-gradient(180deg, rgba(0, 0, 52, 0) 37.9%, rgba(0, 0, 52, 0.0979277) 48%, rgba(0, 0, 52, 0.317019) 59.91%, rgba(0, 0, 52, 0.407564) 72.94%, rgba(0, 0, 52, 0.528391) 86.4%, rgba(0, 0, 52, 0.64) 100%),
    //         linear-gradient(0deg, rgba(0, 0, 52, 0) 63.91%, rgba(0, 0, 52, 0.30314) 85.73%, rgba(0, 0, 52, 0.45) 100%)
    //       `,
    //     }}
    //   >
    //     <span className="range-btn">{selectedUnit.sizeRange}</span>

    //     <div className="row text-white mt-auto">
    //       <div className="col-12 col-md-4 lh-1">
    //         <p className="unit-headings">Common unit sizes:</p>
    //         <p className="unit-p">{selectedUnit.commonSizes}</p>
    //       </div>
    //       <div className="col-12 col-md-4 lh-1 mt-0 mt-md-0">
    //         <p className="unit-headings">Looks like:</p>
    //         <p className="unit-p">{selectedUnit.description}</p>
    //       </div>
    //       <div className="col-12 col-md-4 lh-1 mt-0 mt-md-0">
    //         <p className="unit-headings">What will fit:</p>
    //         <p className="unit-p">{selectedUnit.fits}</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )}
</div>

  );
};

export default HomeHotOffers;
