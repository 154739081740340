import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../shared-components/container/Container";
import HomeCard from "./HomeCard/HomeCard";
import TopCards from "./HomeTopCards/TopCards";
import WelcomeOrange from "./WelcomeOrange/WelcomeOrange";
import WhyOrange from "./WhyOrange/WhyOrange";
import HomeHotOffers from "./HomeHotOffers/HomeHotOffers";
import HomeClientsTestimonials from "./HomeClientsTestimonials/HomeClientsTestimonials";
import { getAllServiceProviders } from "../../actions/serviceProviderActions";
import { USER_REGISTER_RESET } from "../../constants/userConstants";

import "./Home.css";
import StorageJourney from "./StorageJourney/StorageJourney";
import Tutorials from "./Tutorials/Tutorials";
import Learning from "./Learning/Learning";
import StorageListing from "./StorageListing/StorageListing";

const Home = () => {
  const { user } = useSelector((state) => state.userLogin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
    if (user) {
      if (user.service_provider && user.is_provider_verified) {
        // if (user.is_stripe_account_active !== undefined && user.is_stripe_account_active) {
        //   dispatch(getAllServiceProviders());
        //   navigate("/service-provider-dashboard/analytics");
        // } else if (user.is_stripe_account_active !== undefined && !user.is_stripe_account_active) {
        //   navigate("/service-provider-stripe-status");
        // }else {
        //   navigate("/");
        // }
        dispatch(getAllServiceProviders());
        navigate("/service-provider-dashboard/analytics");
      } else if (user.service_provider && !user.is_provider_verified) {
        navigate("/provider-details-verification");
      } else {
        navigate("/");
      }
    }
  }, [user]);

  return (
    <>
      <section
        // id="showcase-area "
        // // style={{
        // //   minHeight: "80vh",
        // //   background:
        // //     'url("https://www.storemygoods.in/assets/images/change_in_images/self-storage-all.jpg")',
        // //   backgroundPosition: "center",
        // //   backgroundRepeat: "no-repeat",
        // // }}

        // style={{
        //   minHeight: "80vh",
        //   background: 'url("/images/banner-background.jpeg")',
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
      >
        <div className="">
          <div className="showcase-content" style={{backgroundColor:'#ffcc00' ,paddingTop:'35px'}}>
            <div className="px-5">
              <HomeCard />
            </div>
          </div>
        </div>
      </section>

      <section id="welcome-orange" className="py-5" style={{backgroundColor:'#FFF2C0' }}>
      <div className="px-5">
          <WelcomeOrange />
        </div>
      </section>

      <section id="storage-journey" className="py-5">
      <div className="px-5">
          <StorageJourney />
        </div>
      </section>

      <section id="storage-journey" className="py-5">
      <div className="px-5">
          <StorageListing />
        </div>
      </section>
      
      <section id="why-orange" className="py-5">
      <div className="px-5">
          <WhyOrange />
        </div>
      </section>

      <section id="some-cards" className="py-5 bg-light">
      <div className="text-center">
      <p className='blueTextColr'
      style={{
        fontSize: '24px',
        fontWeight: 400,
        letterSpacing:'-1px',
        lineHeight:1,
      }}
      >Choosing the right storage unit size can be challenging. </p>
            <p className='blueTextColr sj-heading'>
                Variety of Sizes to
                <span style={{ color: '#FFCC00'}}> Fit Your Requirements.</span>
            </p>
        </div>
        <div className="">
        <HomeHotOffers />
        </div>
      </section>
      {/* Client Testimonials */}
      <section id="clients" className="container mt-5 overflow-hidden">
        <p className='blueTextColr sj-heading ' style={{textAlign: 'left'}}>
          See What Our
          <span style={{ color: '#FFCC00'}}> Our Customers </span>
          <br />
          Says about us
        </p>
        <HomeClientsTestimonials />
      </section>
      <section id="learnings" className="py-5">
      <p className='blueTextColr sj-heading ' style={{textAlign: 'center'}}>
          <span style={{ color: '#FFCC00'}}> Popular </span>
          Learnings
        </p>
      <Learning></Learning>
      </section>
      <section id="videos" className="container mt-5">
      <div className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  <p className='blueTextColr sj-heading' style={{ textAlign: 'center' }}>
    Get
    <span style={{ color: '#FFCC00'}}> Smart </span>
    About
    <span style={{ color: '#FFCC00'}}> Storage.</span>
  </p>
  <p 
    className='blueTextColr w-100 w-lg-50 w-md-75 justify-content-center'
    style={{
      fontSize: '20px',
      fontWeight: 400,
      letterSpacing:'-1px',
      textAlign: 'center',
    }}
  >
    From packing tips to choosing the right space, our reels have you covered. Check out more on our socials!
  </p>
</div>

        <Tutorials></Tutorials>
      </section>
    </>
  );
};

export default Home;
